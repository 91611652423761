import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Seo from "../components/seo";
import TwoColumnLayout from '../components/twoColumnLayout'
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const ImageGrid1x1 = styled(GatsbyImage)`
 width: 100%;
 grid-column-start: 1;
 grid-column-end: 3;
 ${MEDIA.PHONE`
   grid-column-start: 1;
   grid-column-end: 1;
   `};
`

const ImageGrid2x2 = styled(GatsbyImage)`
 width: 100%;
`

const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(50% - 2rem, 0fr));
grid-gap: 2rem;
margin: 2rem;
${MEDIA.PHONE`
  grid-template-columns: repeat(auto-fill, minmax(100%, 0fr));
  `};
`

const TextContainer = styled.div`
position: fixed;
top: 0;
bottom: 0;
max-width: 533px;

z-index: 1000;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
padding-right: 2rem;
padding-top: 2rem;
&::-webkit-scrollbar {
    display: none;
}
${MEDIA.TABLET`
    width: 100%;
    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
  `};
${MEDIA.PHONE`
  width: 100%;
  position: static;
  align-items: left;
  padding: 2rem;
  padding-bottom: 0;
  z-index: 10;
  `};
`

const RNAUTS = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "RNAUTS"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  const images = [];
  for (let i = 0; i < data.allFile.edges.length; i++) {
    images[i] = getImage(data.allFile.edges[i].node)
  }
  return(
    <div>
    <TwoColumnLayout
      Column1Size="66"
      Column1Content={
        <GridContainer>
            <ImageGrid1x1 image={images[0]} alt={data.allFile.edges[0].node.name} key={0}/>
            <ImageGrid1x1 image={images[1]} alt={data.allFile.edges[1].node.name} key={1}/>
            <ImageGrid1x1 image={images[2]} alt={data.allFile.edges[2].node.name} key={2}/>
            <ImageGrid1x1 image={images[3]} alt={data.allFile.edges[3].node.name} key={3}/>
            <ImageGrid2x2 image={images[7]} alt={data.allFile.edges[7].node.name} key={7}/>
            <ImageGrid2x2 image={images[8]} alt={data.allFile.edges[8].node.name} key={8}/>
            <ImageGrid2x2 image={images[9]} alt={data.allFile.edges[9].node.name} key={9}/>
            <ImageGrid2x2 image={images[10]} alt={data.allFile.edges[10].node.name} key={10}/>
            <ImageGrid1x1 image={images[4]} alt={data.allFile.edges[4].node.name} key={4}/>
            <ImageGrid1x1 image={images[6]} alt={data.allFile.edges[6].node.name} key={6}/>
            <ImageGrid1x1 image={images[5]} alt={data.allFile.edges[5].node.name} key={5}/>
        </GridContainer>
      }
      Column2Size="33"
      Column2Content={
        <TextContainer>
        <Seo title="RNA UTS" />
        <h2>
          <i>RNA x UTS || Sense of Belonging</i>
          <br /><br />
          Sense of Belonging is a campaign aimed at creating more awareness to the services available at the University of Technology Sydney. Through creative strategy and in-depth research, the team devised a strategy to create a wider sense of community, identifying key problems behind the university and its students. This greater community allows for more interaction between students to create a unified voice behind student’s feelings towards the university and its services.
          <br /><br />
          The outcome produced large banners used to share students thoughts and feelings alongside a book that details many different voices of students gratitude and ingratitude for the university. This book was printed and shared among many of the senior administrators of UTS.
          <br /><br />
          Created as part of a team including Ady Neshoda, Monica Steiner and Emma Sharp.
          <br /><br />
          Finalist for AGDA 2020 Student Print Awards
        </h2>
      </TextContainer>
      }
      />
      <Header/>
</div>
  )
}

export default RNAUTS;
